import {InputOtp} from "@heroui/input-otp"
import {useForm, Controller, SubmitHandler} from "react-hook-form"
import {Button, Input, Spinner} from "@heroui/react"
import LogoHeader from "../general/LogoHeader"
import {useMemo, useState} from "react"
import supabase from "@/supabaseConfig"
import {useNavigate} from "react-router-dom"
import {AuthApiError} from "@supabase/supabase-js"
import {cn, validateEmail} from "@/lib/utils"
import {validate} from "graphql"

interface FormValues {
  email: string
  otp: string
}

interface OTPContainerProps {
  emailProp?: string
  setError?: (error: boolean) => void
  routeProps?: {
    token_hash?: string
    type?: string
    meta?: string
    redirect_route: string
  }
}

const RequestNewOTP = ({
  type = "href",
  emailValue = "",
  setOTPError = () => {},
}: {
  type: "href" | "button"
  emailValue: string
  setOTPError: (error: string) => void
}) => {
  const [loading, setLoading] = useState(false)

  const requestNewLink = async () => {
    const baseURL = window.location.origin
    setLoading(true)
    try {
      const {data, error} = await supabase.auth.signInWithOtp({
        email: emailValue,
        options: {
          // set this to false if you do not want the user to be automatically signed up
          shouldCreateUser: false,
          emailRedirectTo: `${baseURL}`,
        },
      })
    } catch (error) {
      console.error("Exception when requesting new link:", error)
      setOTPError(error instanceof Error ? error.message : String(error))
      console.log("error", error)
      setLoading(false)
    } finally {
      setTimeout(() => {
        setLoading(false)
      }, 1000)
    }
  }

  const requestElement = (() => {
    switch (type) {
      case "href":
        return (
          <div className="flex flex-row justify-end transition-all">
            <Spinner
              size="sm"
              className={cn(loading ? "opacity-100" : "opacity-0")}
            />
            <a
              href="#"
              onClick={requestNewLink}
              className="self-end text-xs text-onyx-black/60"
            >
              request a new link
            </a>
          </div>
        )
      case "button":
        return (
          <Button
            onPress={requestNewLink}
            size="sm"
            className="h-6 max-w-fit self-end px-2 py-0 text-xs"
            isLoading={loading}
            variant="flat"
            spinner={
              <svg
                className="h-3 w-3 animate-spin text-current"
                fill="none"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                />
                <path
                  className="opacity-75"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  fill="currentColor"
                />
              </svg>
            }
          >
            request a new link
          </Button>
        )
    }
  })()
  return requestElement
}

export default function OTPContainer({
  emailProp,
  setError,
  routeProps,
}: OTPContainerProps) {
  const [OTPError, setOTPError] = useState("")
  const navigate = useNavigate()

  async function verifyOTP(otp_token: string, email: string) {
    try {
      const {data, error} = await supabase.auth.verifyOtp({
        token: otp_token,
        type: "email",
        email: email,
      })
      if (data.session) {
        routeProps?.redirect_route
          ? navigate(
              routeProps.redirect_route +
                "?token_hash=" +
                routeProps.token_hash +
                "&type=" +
                routeProps.type +
                "&meta=" +
                routeProps.meta
            )
          : navigate("/archive")
      } else if (error) {
        console.error("Error verifying OTP:", error.message)
        // Check if the error message indicates an expired or invalid link
        if (error instanceof AuthApiError) {
          console.error("Error verifying OTP:", error.message)
          setOTPError(error.message)
          setError?.(true)
        } else {
          navigate("/login")
        }
      }
    } catch (error) {
      console.error("Exception when verifying OTP:", error)
      console.log("error", error)
      setOTPError(error instanceof Error ? error.message : String(error))
    }
  }

  const {
    handleSubmit,
    control,
    watch,
    formState: {errors},
  } = useForm<FormValues>({
    defaultValues: {
      email: emailProp || "",
      otp: "",
    },
    mode: "onBlur",
  })

  const emailValue = watch("email")

  const isEmailValid = useMemo<boolean>(() => {
    return validateEmail(emailValue)
  }, [emailValue])

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    console.log("email", data.email, "otp", data.otp)
    verifyOTP(data.otp, data.email)
  }

  return (
    <section className="flex w-full max-w-[300px] flex-col gap-4 rounded-xl border border-black/5 bg-white px-4 py-4 transition-all">
      <div className="flex flex-col justify-start gap-2 border-b border-gray-200 pb-4">
        <LogoHeader />
      </div>
      <div className="mt-1 flex flex-col gap-1 ">
        <p className="text-sm font-medium">Security Verification</p>
        <p className="text-sm leading-5 text-onyx-black/60">
          Enter the 6 digit code sent to your email. Didn't request this?
          <a
            href={`mailto:info@obsidianlabs.co`}
            className="px-1 text-slate-500"
          >
            Contact support
          </a>
        </p>
      </div>

      <form className="flex flex-col gap-3" onSubmit={handleSubmit(onSubmit)}>
        <div className={cn("overflow-clip", !emailProp ? "block" : "hidden")}>
          <Controller
            control={control}
            name="email"
            render={({field}) => (
              <Input
                {...field}
                label="Email"
                type="email"
                placeholder="Confirm your login email"
                errorMessage={errors.email?.message}
                isInvalid={!!errors.email || !isEmailValid}
                classNames={{
                  helperWrapper: "mt-1 px-0",
                  label: "text-xs",
                }}
              />
            )}
            rules={{
              required: "Email is required",
              validate: (value) => {
                console.log("value", value)
                return (
                  validateEmail(value) || "Please enter a valid email address"
                )
              },
            }}
          />
        </div>

        <Controller
          control={control}
          name="otp"
          render={({field}) => (
            <InputOtp
              label="6 Digit OTP"
              {...field}
              errorMessage={errors.otp?.message}
              isInvalid={!!errors.otp}
              length={6}
              classNames={{
                helperWrapper: "font-normal",
              }}
              description="Enter the 6 digit OTP"
              // variant="faded"
            />
          )}
          rules={{
            required: "OTP is required",
            minLength: {
              value: 6,
              message: "Please enter a valid OTP",
            },
          }}
        />

        <div className="flex w-full flex-row justify-between gap-2">
          <Button
            type="submit"
            color="primary"
            className="mt-2 max-w-fit"
            variant="ghost"
          >
            Verify OTP
          </Button>
          {/* <RequestNewOTP
            type="href"
            emailValue={emailValue}
            setOTPError={setOTPError}
          /> */}
        </div>
      </form>

      <div
        className={cn(
          "mt-2 flex flex-col transition-all",
          OTPError ? "max-h-64 opacity-100" : "max-h-0 opacity-0"
        )}
      >
        <p className=" text-xs text-danger-500 ">
          <p className="text-sm font-medium">OTP Error</p>
          <p>
            {OTPError}. Request a new OTP and if the issue persists, please{" "}
            <a href="mailto:info@obsidianlabs.co">contact support.</a>
          </p>
        </p>
        <RequestNewOTP
          type="button"
          emailValue={emailValue}
          setOTPError={setOTPError}
        />
      </div>
    </section>
  )
}
