import {Button, Input} from "@heroui/react"

import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
} from "@heroui/drawer"
import {
  AlertGroupBackgroundColors,
  AlertGroupBackgroundColorsFull,
  IconComponent,
} from "@/components/archive/AlertsView/shared"
import React from "react"
import AlertConfigurationItem from "./AlertConfigurationItem"
import NewAlert from "./NewAlert"
import {alertGroups} from "./AlertTypes"
import {twMerge} from "tailwind-merge"

const SetAlertsDrawerMenu = ({
  isOpen,
  onOpenChange,
  flag,
  //   submitFilters,
}: {
  isOpen: boolean
  onOpenChange: (open: boolean) => void
  flag?: "compliance" | "bi"
  //   submitFilters: (filters: OptionMap[]) => void
}) => {
  //   const [options, setOptions] = React.useState<OptionMap[]>([])

  //   function addOption(optionType: OptionTypes) {
  //     if (optionType === "date_range") {
  //       setOptions((prev) => {
  //         const newMap = [...prev]
  //         newMap.push({
  //           type: "date_range",
  //           temp_id: crypto.randomUUID(),
  //           value: {start: "", end: ""},
  //         })
  //         return newMap
  //       })
  //     }
  //     if (optionType === "contributors") {
  //       setOptions((prev) => {
  //         const newMap = [...prev]
  //         newMap.push({
  //           type: "contributors",
  //           temp_id: crypto.randomUUID(),
  //           value: {contributors: ""},
  //         })
  //         return newMap
  //       })
  //     }
  //   }

  //   function removeOption(option: OptionMap) {
  //     setOptions((prev) => {
  //       return prev.filter((opt) => opt.temp_id !== option.temp_id)
  //     })
  //   }

  //   function changeValue(
  //     option: OptionMap,
  //     type: OptionTypes,
  //     value: OptionMap["value"]
  //   ) {
  //     // update the value of the option
  //     if (type === "contributors") {
  //       setOptions((prev) => {
  //         return prev.map((opt) => {
  //           if (opt.temp_id === option.temp_id) {
  //             return {...opt, value: value}
  //           }
  //           return opt
  //         })
  //       })
  //     }

  //     if (type === "date_range") {
  //       console.log("value for new date range", value)
  //       setOptions((prev) => {
  //         return prev.map((opt) => {
  //           if (opt.temp_id === option.temp_id) {
  //             return {...opt, value: value}
  //           }
  //           return opt
  //         })
  //       })
  //     }
  //   }

  return (
    <Drawer
      isOpen={isOpen}
      onOpenChange={() => {
        onOpenChange(false)
        // submitFilters(options)
      }}
      size="4xl"
    >
      <DrawerContent>
        {(onClose) => (
          <>
            <DrawerHeader className="flex flex-col gap-1">
              <p className="text-lg font-medium">
                Intelligent AI Alerts Configuration
              </p>
              <p className="text-sm font-normal">
                Set alerts that will notify your team under certain conditions
                or violations. Quartz will look for compliance violations or
                suspicious activity.
              </p>
            </DrawerHeader>
            <DrawerBody className="flex h-fit flex-col gap-2 rounded-md border border-black/5 bg-onyx-slate/15 p-2 py-1 bg-grid-small-onyx-black/15">
              <div className="flex flex-col gap-2 border-b border-black/5 px-2 pb-1">
                <p className="text-sm font-medium">Active Alert Policies:</p>
              </div>
              <div className="flex flex-col gap-2">
                {alertGroups
                  .slice(flag === "bi" ? alertGroups.length - 1 : 0)
                  .map((group) => (
                    <div
                      key={group.name}
                      className="flex flex-col gap-1 overflow-hidden rounded-md border border-black/5 bg-onyx-white px-1 py-1"
                    >
                      <div
                        className={twMerge(
                          `flex w-fit flex-row items-center gap-1 rounded-xl border border-black/5 bg-white p-1`
                        )}
                      >
                        <div className=" flex flex-row items-center gap-2">
                          <div
                            className={twMerge(
                              `ml-2 h-2 w-2 rounded-full`,
                              AlertGroupBackgroundColorsFull[group.name]
                            )}
                          />
                          <p className="font-regular mr-1 text-xs">
                            {group.name} Policy
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-col overflow-hidden rounded-md">
                        {group.subtypes.map((subtype) => (
                          <AlertConfigurationItem
                            key={subtype.name}
                            {...subtype}
                            type={group.name}
                            subtype={subtype.name}
                            idx={0}
                          />
                        ))}
                      </div>
                    </div>
                  ))}
                {/* <AlertConfigurationItem
                  idx={0}
                  note="mentions of keywords: stock ticker or similar keywords"
                  type="SEC and FINRA Compliance Risks"
                  priority="!!!"
                  ifCondition="if the message contains a stock ticker or similar keywords"
                />
                <AlertConfigurationItem
                  idx={1}
                  note="mentions of profanities"
                  type="Profanity Flag"
                  priority="!"
                />
                <AlertConfigurationItem
                  idx={2}
                  note="someone hasn’t engaged Quartz in over 48 hours (weekdays only)"
                  type="Platform Subversion"
                />
                <AlertConfigurationItem
                  idx={3}
                  note="missing context / someone continues a conversation off platform and resumes it in Quartz (circumvention)"
                  type="Platform Subversion"
                />
                <AlertConfigurationItem
                  idx={4}
                  note="someone is texting outside of standard working hours "
                  type="Platform Subversion"
                />
                <AlertConfigurationItem
                  idx={5}
                  note="someone is making marketing guarantees / promises that break compliance guidelines"
                  type="Marketing Guarantees"
                />
                <AlertConfigurationItem
                  idx={6}
                  note="client distress signal indicating a need to investigate for advisor misconduct/ inappropriate behavior"
                  type="Client Distress"
                /> */}
              </div>
              {/* <NewAlert /> */}
            </DrawerBody>
            <DrawerFooter>
              <Button
                color="danger"
                size="sm"
                variant="light"
                onPress={onClose}
              >
                Close
              </Button>
              <Button
                color="secondary"
                variant="ghost"
                className="w-fit text-xs font-medium "
                size="sm"
                onPress={onClose}
              >
                Create New Alert
              </Button>
            </DrawerFooter>
          </>
        )}
      </DrawerContent>
    </Drawer>
  )
}

export default SetAlertsDrawerMenu
