import React from "react"
import {Outlet} from "react-router-dom"
import {Sidebar, SidebarBody, SidebarLink} from "../components/general/Sidebar"
import {
  IconLogout2,
  IconSettings,
  IconLogs,
  IconUser,
  IconAlertSquare,
  IconSpaces,
  IconBrandBackbone,
} from "@tabler/icons-react"
import Logo from "../components/general/Logo"
import supabase from "@/supabaseConfig"
import {useNavigate} from "react-router-dom"
import {Chip} from "@heroui/react"
import {LockedFeatureIndicator} from "@/components/general/ui/LockedFeatureIndicator"


function Root() {
  const navigate = useNavigate()
  async function handleSignOut() {
    console.log("signing out")
    try {
      const {error} = await supabase.auth.signOut()
      console.log("signing out - error", error)
      if (error) {
        console.error("Error signing out:", error)
      } else {
        navigate("/login")
      }
    } catch (exception) {
      console.error("Exception during sign out:", exception)
    }
  }

  const links = [
    {
      label: "Archive",
      href: "/archive",
      icon: (
        <IconLogs className="h-5 w-5 flex-shrink-0 text-neutral-700 dark:text-neutral-200" />
      ),
    },
    {
      label: "Users",
      href: "/user",
      icon: (
        <IconUser className="h-5 w-5 flex-shrink-0 text-neutral-700 dark:text-neutral-200" />
      ),
    },
    // {
    //   label: "Compliance Alerts",
    //   href: "/alerts",
    //   icon: (
    //     <IconSpaces
    //       className="h-5 w-5 flex-shrink-0 text-neutral-700 dark:text-neutral-200"
    //       color="grey"
    //     />
    //   ),
    // },
    // {
    //   label: "Business Intelligence Alerts",
    //   href: "/business-intelligence",
    //   icon: (
    //     <IconBrandBackbone
    //       className="h-5 w-5 flex-shrink-0 text-neutral-700 dark:text-neutral-200"
    //       color="grey"
    //     />
    //   ),
    // },
    // {
    //   label: "Settings",
    //   href: "/settings",
    //   icon: (
    //     <IconSettings
    //       className="h-5 w-5 flex-shrink-0 text-neutral-700 dark:text-neutral-200"
    //       color="grey"
    //     />
    //   ),
    // },
    {
      label: "Logout",
      href: "#",
      icon: (
        <IconLogout2 className="h-5 w-5 flex-shrink-0 text-neutral-700 dark:text-neutral-200" />
      ),
    },
  ]
  const [open, setOpen] = React.useState(false)

  return (
    <div className="w-lvh flex h-screen flex-col items-start bg-onyx-slate text-gray-700">
      {/* <div className="flex w-full shrink-0">
        <LogoHeader />
        <Dropdown>
          <DropdownTrigger>
            <Button className="ml-auto mr-4 mt-2 bg-transparent">
              <Icons
                iconName="settings"
                strokeColor="currentColor"
                scalar={1}
              />
            </Button>
          </DropdownTrigger>
          <DropdownMenu>
            <DropdownItem
              onClick={handleSignOut}
              key="signout"
              startContent={
                <Icons iconName="logout" strokeColor="red" scalar={1} />
              }
            >
              <p className="text-red-500">Sign Out</p>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div> */}

      <div className="flex h-1 w-full flex-1">
        <Sidebar open={open} setOpen={setOpen}>
          <SidebarBody className="justify-between gap-10">
            <div className="flex flex-1 flex-col overflow-y-auto">
              <div className="flex flex-col items-start gap-2">
                <Logo type="svg" size={18} />
              </div>

              <div className="mt-8 flex flex-col gap-2">
                {links.map((link, idx) => (
                  <>
                    {link.label !== "Logout" && (
                      <SidebarLink
                        key={idx}
                        link={link}
                        locked={
                          link.label === "Alerts" || link.label === "Settings"
                        }
                      />
                    )}
                  </>
                ))}
              </div>
            </div>

            <div>
              {/* <TrailVersionIndicator open={open} /> */}
              <div onClick={() => handleSignOut()}>
                <SidebarLink link={links[links.length - 1]} />
              </div>
            </div>
            {/* <SidebarLink
                link={{
                  label: "Manu Arora",
                  href: "#",
                  icon: (
                    <Image
                      src="https://assets.aceternity.com/manu.png"
                      className="h-7 w-7 flex-shrink-0 rounded-full"
                      width={50}
                      height={50}
                      alt="Avatar"
                    />
                  ),
                }}
              /> */}
          </SidebarBody>
        </Sidebar>
        <div className="my-4 flex w-full flex-1">
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default Root
