import * as TablerIcons from "@tabler/icons-react"
import {cn} from "@heroui/react"
import {twMerge} from "tailwind-merge"

export const IconComponent = ({
  iconNumber,
  color,
}: {
  iconNumber: number
  color: string
}) => {
  const iconKey = `IconNumber${iconNumber}Small` as keyof typeof TablerIcons

  const IconComponent = (TablerIcons[iconKey] ||
    TablerIcons.IconNumber0Small) as React.ComponentType<any>
  return <IconComponent color={color || "#000000"} size={18} />
}

export const ItemCounter = ({number}) => (
  <div className="flex items-center gap-1 text-default-400">
    <span className="text-small">{number}</span>
    <TablerIcons.IconChevronRight className="text-xl" />
  </div>
)

export const IconWrapper = ({children, className}) => (
  <div
    className={cn(
      className,
      "flex h-7 w-7 items-center justify-center rounded-small"
    )}
  >
    {children}
  </div>
)

export enum AlertGroupEnum {
  nonCompliantCommunications = "Non-Compliant Communications",
  advertisingAndMarketingViolations = "Advertising / Marketing Violations",
  tradeAndTransactionMonitoringAlerts = "Trade / Transaction Monitoring Alerts",
  personalTradingAndEmployeeConduct = "Personal Trading / Employee Conduct",
  clientAndInvestorProtectionAlerts = "Client / Investor Protection Alerts",
  policyAndRegulatoryBreaches = "Policy / Regulatory Breaches",
  escalationAndAuditAlerts = "Escalation / Audit Alerts",
  behavioralAndSentimentAnalysisAlerts = "Behavioral and Sentiment Analysis Alerts",
}

export enum AlertGroupBackgroundColors {
  nonCompliantCommunications = "bg-blue-500/10",
  advertisingAndMarketingViolations = "bg-red-500/10",
  tradeAndTransactionMonitoringAlerts = "bg-green-500/10",
  personalTradingAndEmployeeConduct = "bg-yellow-500/10",
  clientAndInvestorProtectionAlerts = "bg-purple-500/10",
  policyAndRegulatoryBreaches = "bg-pink-500/10",
  escalationAndAuditAlerts = "bg-blue-500/10",
  behavioralAndSentimentAnalysisAlerts = "bg-orange-500/10",
}

export const AlertGroupBackgroundColorsFull: Record<AlertGroupEnum, string> = {
  [AlertGroupEnum.nonCompliantCommunications]: "bg-blue-500",
  [AlertGroupEnum.advertisingAndMarketingViolations]: "bg-red-500",
  [AlertGroupEnum.tradeAndTransactionMonitoringAlerts]: "bg-green-500",
  [AlertGroupEnum.personalTradingAndEmployeeConduct]: "bg-yellow-500",
  [AlertGroupEnum.clientAndInvestorProtectionAlerts]: "bg-purple-500",
  [AlertGroupEnum.policyAndRegulatoryBreaches]: "bg-pink-500",
  [AlertGroupEnum.escalationAndAuditAlerts]: "bg-blue-500",
  [AlertGroupEnum.behavioralAndSentimentAnalysisAlerts]: "bg-orange-500",
}

export const AlertGroupTextColorsFull: Record<AlertGroupEnum, string> = {
  [AlertGroupEnum.nonCompliantCommunications]: "text-blue-500",
  [AlertGroupEnum.advertisingAndMarketingViolations]: "text-red-500",
  [AlertGroupEnum.tradeAndTransactionMonitoringAlerts]: "text-green-500",
  [AlertGroupEnum.personalTradingAndEmployeeConduct]: "text-yellow-500",
  [AlertGroupEnum.clientAndInvestorProtectionAlerts]: "text-purple-500",
  [AlertGroupEnum.policyAndRegulatoryBreaches]: "text-pink-500",
  [AlertGroupEnum.escalationAndAuditAlerts]: "text-blue-500",
  [AlertGroupEnum.behavioralAndSentimentAnalysisAlerts]: "text-orange-500",
}

export enum AlertGroupTextColors {
  nonCompliantCommunications = "text-blue-500",
  advertisingAndMarketingViolations = "text-red-500",
  tradeAndTransactionMonitoringAlerts = "text-green-500",
  personalTradingAndEmployeeConduct = "text-yellow-500",
  clientAndInvestorProtectionAlerts = "text-purple-500",
  policyAndRegulatoryBreaches = "text-pink-500",
  escalationAndAuditAlerts = "text-blue-500",
  behavioralAndSentimentAnalysisAlerts = "text-orange-500",
}

export enum AlertGroupBorderColors {
  nonCompliantCommunications = "border-blue-500",
  advertisingAndMarketingViolations = "border-red-500",
  tradeAndTransactionMonitoringAlerts = "border-green-500",
  personalTradingAndEmployeeConduct = "border-yellow-500",
  clientAndInvestorProtectionAlerts = "border-purple-500",
  policyAndRegulatoryBreaches = "border-pink-500",
  escalationAndAuditAlerts = "border-blue-500",
  behavioralAndSentimentAnalysisAlerts = "border-orange-500",
}

export const AlertGroupIconMap = (className?: string) => ({
  [AlertGroupEnum.nonCompliantCommunications]: (
    <TablerIcons.IconMessageCircle size={24} className={className} />
  ),
  [AlertGroupEnum.advertisingAndMarketingViolations]: (
    <TablerIcons.IconAd2 size={24} className={className} />
  ),
  [AlertGroupEnum.tradeAndTransactionMonitoringAlerts]: (
    <TablerIcons.IconCurrencyDollar size={24} className={className} />
  ),
  [AlertGroupEnum.personalTradingAndEmployeeConduct]: (
    <TablerIcons.IconUserCheck size={24} className={className} />
  ),
  [AlertGroupEnum.clientAndInvestorProtectionAlerts]: (
    <TablerIcons.IconShieldCheck size={24} className={className} />
  ),
  [AlertGroupEnum.policyAndRegulatoryBreaches]: (
    <TablerIcons.IconFileAlert size={24} className={className} />
  ),
  [AlertGroupEnum.escalationAndAuditAlerts]: (
    <TablerIcons.IconBell size={24} className={className} />
  ),
  [AlertGroupEnum.behavioralAndSentimentAnalysisAlerts]: (
    <TablerIcons.IconChartLine size={24} className={className} />
  ),
})

export enum AlertTypeEnum {
  unapprovedCommunicationChannels = "Unapproved Communication Channels",
  unapprovedSocialMediaUsage = "Unapproved Social Media Usage",
  misleadingOrPromissoryLanguage = "Misleading or Promissory Language",
  misleadingAdvertising = "Misleading Advertising",
  platformSubversion = "Platform Subversion",
  useOfTestimonials = "Use of Testimonials",
  incompleteOrMissingDisclosures = "Incomplete or Missing Disclosures",
  unauthorizedTrading = "Unauthorized Trading",
  insiderTrading = "Insider Trading",
  crossTrading = "Cross-Trading",
  tradeAllocations = "Trade Allocations",
  personalAccountTrading = "Personal Account Trading",
  giftsAndEntertainment = "Gifts and Entertainment",
  outsideBusinessActivities = "Outside Business Activities (OBAs)",
  politicalContributions = "Political Contributions",
  complaintHandling = "Complaint Handling",
  highRiskTransactions = "High-Risk Transactions",
  feeDisclosures = "Fee Disclosures",
  elderFinancialAbuse = "Elder Financial Abuse",
  conflictOfInterest = "Conflict of Interest",
  confidentialityViolations = "Confidentiality Violations",
  marketManipulation = "Market Manipulation",
  lateOrMissingFilings = "Late or Missing Filings",
  escalatedIssues = "Escalated Issues",
  dataAccessBreaches = "Data Access Breaches",
  auditPreparation = "Audit Preparation",
  anomalousBehavior = "Anomalous Behavior",
  highRiskKeywords = "High-Risk Keywords",
  clientDistressSignals = "Client Distress Signals",
}
export const AlertTypeMessageIconItem = (className?: string) => ({
  // "Profanity Flag": (
  //   <TablerIcons.IconFlame size={20} className="text-warning-500" />
  // ),
  // "Platform Subversion": (
  //   <TablerIcons.IconGitBranchDeleted size={20} className="text-primary-500" />
  // ),
  // "Platform Inactivity": (
  //   <TablerIcons.IconClock size={20} className="text-secondary-500" />
  // ),
  // "Marketing Guarantees": (
  //   <TablerIcons.IconAdCircle size={20} className="text-secondary-500" />
  // ),
  // "Client Distress": (
  //   <TablerIcons.IconAddressBook size={20} className="text-danger-500" />
  // ),
  // "Keyword Flag": (
  //   <TablerIcons.IconBracketsContain size={20} className="text-lime-500" />
  // ),
  // "Keyword Alert": (
  //   <TablerIcons.IconBracketsContain size={20} className="text-lime-500" />
  // ),
  // "General Alert": <></>,
  // "Potential Fraud": <></>,
  // "Potential Compliance Violation": <></>,
  [AlertTypeEnum.unapprovedCommunicationChannels]: (
    <TablerIcons.IconAlertTriangle size={20} className={className} />
  ),
  [AlertTypeEnum.unapprovedSocialMediaUsage]: (
    <TablerIcons.IconBrandTwitter size={20} className={className} />
  ),
  [AlertTypeEnum.misleadingOrPromissoryLanguage]: (
    <TablerIcons.IconMessageDots size={20} className={className} />
  ),
  [AlertTypeEnum.misleadingAdvertising]: (
    <TablerIcons.IconAd2 size={20} className={className} />
  ),
  [AlertTypeEnum.useOfTestimonials]: (
    <TablerIcons.IconUserCheck size={20} className={className} />
  ),
  [AlertTypeEnum.incompleteOrMissingDisclosures]: (
    <TablerIcons.IconFileAlert size={20} className={className} />
  ),
  [AlertTypeEnum.unauthorizedTrading]: (
    <TablerIcons.IconCurrencyDollarOff size={20} className={className} />
  ),
  [AlertTypeEnum.insiderTrading]: (
    <TablerIcons.IconLock size={20} className={className} />
  ),
  [AlertTypeEnum.crossTrading]: (
    <TablerIcons.IconArrowsRightLeft size={20} className={className} />
  ),
  [AlertTypeEnum.tradeAllocations]: (
    <TablerIcons.IconLayoutGrid size={20} className={className} />
  ),
  [AlertTypeEnum.personalAccountTrading]: (
    <TablerIcons.IconUser size={20} className={className} />
  ),
  [AlertTypeEnum.giftsAndEntertainment]: (
    <TablerIcons.IconGift size={20} className={className} />
  ),
  [AlertTypeEnum.outsideBusinessActivities]: (
    <TablerIcons.IconBriefcase size={20} className={className} />
  ),
  [AlertTypeEnum.politicalContributions]: (
    <TablerIcons.IconMoneybag size={20} className={className} />
  ),
  [AlertTypeEnum.complaintHandling]: (
    <TablerIcons.IconAlertCircle size={20} className={className} />
  ),
  [AlertTypeEnum.highRiskTransactions]: (
    <TablerIcons.IconActivity size={20} className={className} />
  ),
  [AlertTypeEnum.feeDisclosures]: (
    <TablerIcons.IconFileDollar size={20} className={className} />
  ),
  [AlertTypeEnum.elderFinancialAbuse]: (
    <TablerIcons.IconAlertOctagon size={20} className={className} />
  ),
  [AlertTypeEnum.conflictOfInterest]: (
    <TablerIcons.IconGitPullRequestDraft size={20} className={className} />
  ),
  [AlertTypeEnum.confidentialityViolations]: (
    <TablerIcons.IconLockOff size={20} className={className} />
  ),
  [AlertTypeEnum.marketManipulation]: (
    <TablerIcons.IconTrendingDown size={20} className={className} />
  ),
  [AlertTypeEnum.lateOrMissingFilings]: (
    <TablerIcons.IconCalendarOff size={20} className={className} />
  ),
  [AlertTypeEnum.escalatedIssues]: (
    <TablerIcons.IconArrowUp size={20} className={className} />
  ),
  [AlertTypeEnum.dataAccessBreaches]: (
    <TablerIcons.IconDatabaseOff size={20} className={className} />
  ),
  [AlertTypeEnum.auditPreparation]: (
    <TablerIcons.IconClipboardList size={20} className={className} />
  ),
  [AlertTypeEnum.anomalousBehavior]: (
    <TablerIcons.IconAlertCircle size={20} className={className} />
  ),
  [AlertTypeEnum.highRiskKeywords]: (
    <TablerIcons.IconKey size={20} className={className} />
  ),
  [AlertTypeEnum.clientDistressSignals]: (
    <TablerIcons.IconHeartBroken size={20} className={className} />
  ),
  [AlertTypeEnum.platformSubversion]: (
    <TablerIcons.IconGitBranchDeleted size={20} className={className} />
  ),
})

export const AlertTypeMessageIcon = ({
  message,
  type,
  className,
  subtitle,
}: {
  message?: string
  type: string
  subtitle?: string
  className?: string
}) => {
  return (
    <div className="group flex flex-row items-center gap-1">
      <div
        className={twMerge(
          `flex w-fit flex-row items-center gap-1 rounded-xl border border-black/5 bg-white p-1`,
          className
        )}
      >
        <div className="flex flex-row gap-1">
          <p className="text-xs font-medium">
            {AlertGroupEnum[type as keyof typeof AlertGroupEnum]}{" "}
            {message ? "" : "Alert"}
          </p>
        </div>
      </div>
      {subtitle && (
        <div
          className={twMerge(
            `flex flex-row items-start rounded-lg border px-1 py-0.5`,
            AlertGroupBackgroundColors[type],
            AlertGroupBorderColors[type],
            AlertGroupTextColors[type]
          )}
        >
          <div className="h-4 w-4">
            {AlertTypeMessageIconItem(AlertTypeEnum[subtitle])[
              AlertTypeEnum[subtitle]
            ] ? (
              AlertTypeMessageIconItem("h-4 w-4")[AlertTypeEnum[subtitle]]
            ) : (
              <TablerIcons.IconClock size={20} color="#707070" />
            )}
          </div>
          <div className="max-w-0 overflow-clip transition-all duration-300 group-hover:max-w-screen-xl">
            <p className="w-fit whitespace-nowrap text-xs font-medium">
              {AlertTypeEnum[subtitle as keyof typeof AlertTypeEnum]}
            </p>
          </div>
        </div>
      )}
    </div>
  )
}

export const AlertSubTypeMessageIcon = ({
  message,
  type,
  className,
  subtitle,
}: {
  message?: string
  type: string
  subtitle?: string
  className?: string
}) => {
  return (
    <div
      className={twMerge(
        `flex h-fit w-fit flex-row items-center gap-1 rounded-xl border border-black/5 bg-white p-1 px-[0.325rem]`,
        className
      )}
    >
      <div className="flex flex-row gap-1">
        <div
          className={twMerge(
            `h-4 w-4`,
            // AlertGroupBackgroundColorsFull[type],
            // AlertGroupBorderColorsFull[type],
            AlertGroupTextColorsFull[type]
          )}
        >
          {AlertTypeMessageIconItem("h-4 w-4")[subtitle]}
        </div>
        <p className="text-xs font-medium">
          {subtitle}
          {message ? "" : " Alert"}
        </p>
      </div>
    </div>
  )
}
