import React from "react"
import {
  Accordion,
  AccordionItem,
  Badge,
  Chip,
  cn,
  ListboxSection,
  useDisclosure,
} from "@heroui/react"
import {Button, Listbox, ListboxItem} from "@heroui/react"
import AlertTableItemHeader from "@/components/archive/AlertsView/AlertTableItemHeader"

import {
  IconAlertSquareRoundedFilled,
  IconBook,
  IconBug,
  IconCalendar,
  IconCircleDashedCheck,
  IconCircleDashedX,
  IconDeviceWatchHeart,
  IconGitPullRequest,
  IconInputAi,
  IconLayout,
  IconMessage,
  IconPlaystationCircle,
  IconProgressAlert,
  IconProgressCheck,
  IconTag,
  IconUser,
} from "@tabler/icons-react"
import {ItemCounter, IconWrapper} from "@/components/archive/AlertsView/shared"
import * as TablerIcons from "@tabler/icons-react"
import alertsStub from "@/data/alertsStub.json"
import SetAlertsDrawerMenu from "@/components/archive/AlertsView/AlertsDrawerMenu"
import {twMerge} from "tailwind-merge"
import {Input} from "@heroui/react"
import AlertsTableItemView from "@/components/archive/AlertsView/AlertTableItemView"
import ChannelIndicator from "@/components/archive/ChannelIndicator"
import {IconComponent} from "@/components/archive/AlertsView/shared"
export function ListComponent() {
  return (
    <Listbox
      aria-label="User Menu"
      onAction={(key) => alert(key)}
      className="max-w-[300px] gap-0 divide-y divide-default-300/50 overflow-visible rounded-medium bg-content1 p-0 shadow-small dark:divide-default-100/80"
      itemClasses={{
        base: "px-3 first:rounded-t-medium last:rounded-b-medium rounded-none gap-3 h-12 data-[hover=true]:bg-default-100/80",
      }}
    >
      <ListboxItem
        key="issues"
        endContent={<ItemCounter number={13} />}
        startContent={
          <IconWrapper className="bg-success/10 text-success">
            <IconBug className="text-lg " />
          </IconWrapper>
        }
      >
        Issues
      </ListboxItem>
      <ListboxItem
        key="pull_requests"
        endContent={<ItemCounter number={6} />}
        startContent={
          <IconWrapper className="bg-primary/10 text-primary">
            <IconGitPullRequest className="text-lg " />
          </IconWrapper>
        }
      >
        Pull Requests
      </ListboxItem>
      <ListboxItem
        key="discussions"
        endContent={<ItemCounter number={293} />}
        startContent={
          <IconWrapper className="bg-secondary/10 text-secondary">
            <IconMessage className="text-lg " />
          </IconWrapper>
        }
      >
        Discussions
      </ListboxItem>
      <ListboxItem
        key="actions"
        endContent={<ItemCounter number={2} />}
        startContent={
          <IconWrapper className="bg-warning/10 text-warning">
            <IconPlaystationCircle className="text-lg " />
          </IconWrapper>
        }
      >
        Actions
      </ListboxItem>
      <ListboxItem
        key="projects"
        endContent={<ItemCounter number={4} />}
        startContent={
          <IconWrapper className="bg-default/50 text-foreground">
            <IconLayout className="text-lg " />
          </IconWrapper>
        }
      >
        Projects
      </ListboxItem>
      <ListboxItem
        key="releases"
        className="group h-auto py-3"
        endContent={<ItemCounter number={399} />}
        startContent={
          <IconWrapper className="bg-primary/10 text-primary">
            <IconTag className="text-lg" />
          </IconWrapper>
        }
        textValue="Releases"
      >
        <div className="flex flex-col gap-1">
          <span>Releases</span>
          <div className="rounded-small bg-default-100 px-2 py-1 group-data-[hover=true]:bg-default-200">
            <span className="text-tiny text-default-600">
              @heroui/react@2.0.10
            </span>
            <div className="flex gap-2 text-tiny">
              <span className="text-default-500">49 minutes ago</span>
              <span className="text-success">Latest</span>
            </div>
          </div>
        </div>
      </ListboxItem>
      <ListboxItem
        key="contributors"
        endContent={<ItemCounter number={79} />}
        startContent={
          <IconWrapper className="bg-warning/10 text-warning">
            <IconUser />
          </IconWrapper>
        }
      >
        Contributors
      </ListboxItem>
      <ListboxItem
        key="watchers"
        endContent={<ItemCounter number={82} />}
        startContent={
          <IconWrapper className="bg-default/50 text-foreground">
            <IconDeviceWatchHeart />
          </IconWrapper>
        }
      >
        Watchers
      </ListboxItem>
      <ListboxItem
        key="license"
        endContent={<span className="text-small text-default-400">MIT</span>}
        startContent={
          <IconWrapper className="bg-danger/10 text-danger dark:text-danger-500">
            <IconBook />
          </IconWrapper>
        }
      >
        License
      </ListboxItem>
    </Listbox>
  )
}

export function DropdownMenuComponent() {
  return (
    <Listbox
      aria-label="User Menu"
      onAction={(key) => alert(key)}
      className="z-50 max-w-[300px] gap-0 divide-y divide-default-300/50 overflow-visible rounded-medium bg-content1 p-0 shadow-small dark:divide-default-100/80"
      itemClasses={{
        base: "px-3 first:rounded-t-medium last:rounded-b-medium rounded-none gap-3 h-12 data-[hover=true]:bg-default-100/80",
      }}
    >
      <ListboxSection title="Filter by:" className="ml-2 mt-2">
        <ListboxItem
          key="issues"
          endContent={<ItemCounter number={13} />}
          startContent={
            <IconWrapper className="bg-success/10 text-success">
              <IconCalendar className="text-lg " />
            </IconWrapper>
          }
        >
          Date Ranges
        </ListboxItem>
        <ListboxItem
          key="pull_requests"
          endContent={<ItemCounter number={6} />}
          startContent={
            <IconWrapper className="bg-primary/10 text-primary">
              <IconInputAi className="text-lg " />
            </IconWrapper>
          }
        >
          Keywords
        </ListboxItem>
        {/* <ListboxItem
        key="discussions"
        endContent={<ItemCounter number={293} />}
        startContent={
          <IconWrapper className="bg-secondary/10 text-secondary">
            <IconMessage className="text-lg " />
          </IconWrapper>
        }
      >
        Keywords
      </ListboxItem> */}
        <ListboxItem
          key="projects"
          endContent={<ItemCounter number={4} />}
          startContent={
            <IconWrapper className="bg-default/50 text-foreground">
              <IconLayout className="text-lg " />
            </IconWrapper>
          }
        >
          Cases
        </ListboxItem>
        <ListboxItem
          key="contributors"
          endContent={<ItemCounter number={79} />}
          startContent={
            <IconWrapper className="bg-warning/10 text-warning">
              <IconUser />
            </IconWrapper>
          }
        >
          Users
        </ListboxItem>
      </ListboxSection>
    </Listbox>
  )
}

const Indicator = ({
  isProcessed,
  type,
  label,
  text,
  containerClass,
}: {
  isProcessed: boolean
  type: "status" | "processing"
  label?: string
  text?: string
  containerClass?: string
}) => {
  const color = isProcessed ? "bg-green-500" : "bg-red-500"
  return (
    // </Tooltip>
    <div
      className={twMerge(
        `flex w-fit flex-row items-center gap-1 rounded-xl border border-black/5 bg-white p-1`,
        containerClass
      )}
    >
      {/* <div className={`h-2 w-2  rounded-full ${color}`}></div> */}
      {type === "status" ? (
        isProcessed ? (
          <IconCircleDashedCheck className="h-4 w-4" color="green" />
        ) : (
          <IconCircleDashedX className="h-4 w-4" color="red" />
        )
      ) : isProcessed ? (
        <IconProgressCheck className="h-4 w-4" color="green" />
      ) : (
        <IconProgressAlert className="h-4 w-4" color="orange" />
      )}
      <div className="flex flex-row items-center gap-2">
        {label && <p className="text-sm font-medium">{label}</p>}
        {text && <p className="font-regular text-xs">{text}</p>}
      </div>
    </div>
  )
}

const SetAlertsMenu = () => {
  return (
    <div className="flex h-fit flex-col gap-2 rounded-md border border-black/5 bg-onyx-slate/15 p-2 py-1 bg-grid-small-onyx-black/15">
      <div className="flex flex-col gap-0 px-2 py-1">
        <p className="text-lg font-medium">Set Alerts</p>
        <p className="text-sm">
          Set alerts that will notify your team under certain conditions or
          violations. Quartz will look for compliance violations or suspicious
          activity.
        </p>
      </div>
      <div className="flex flex-col gap-2 border-b border-black/5 px-2 pb-2">
        <p className="text-sm font-medium">Alert Policies:</p>
      </div>
      <Input
        // label="Alert Me When:"
        startContent={
          <div className="flex flex-row items-center gap-1 rounded-md bg-onyx-black">
            <IconComponent iconNumber={1} color="#ffffff" />
          </div>
        }
        placeholder="someone mentions a stock ticker"
        value="someone mentions a stock ticker"
      ></Input>
      <Input
        // label="Alert Me When:"
        placeholder="someone continues a conversation off platform and resumes it in Quartz (circumvention)"
        value="someone continues a conversation off platform and resumes it in Quartz (circumvention)"
        startContent={
          <div className="flex flex-row items-center gap-1 rounded-md bg-onyx-black">
            <IconComponent iconNumber={2} color="#ffffff" />
          </div>
        }
      ></Input>
      <Input
        // label="Alert Me When:"
        placeholder="someone hasn’t engaged Quartz in over 48 hours (weekdays only)"
        value="someone hasn’t engaged Quartz in over 48 hours (weekdays only)"
        startContent={
          <div className="flex flex-row items-center gap-1 rounded-md bg-onyx-black">
            <IconComponent iconNumber={3} color="#ffffff" />
          </div>
        }
      ></Input>
      <Input
        // label="Alert Me When:"
        placeholder="someone is texting outside of standard working hours "
        value="someone is texting outside of standard working hours "
        startContent={
          <div className="flex flex-row items-center gap-1 rounded-md bg-onyx-black">
            <IconComponent iconNumber={4} color="#ffffff" />
          </div>
        }
      ></Input>

      <Button
        color="secondary"
        variant="ghost"
        className="w-fit self-end text-xs font-medium "
        size="sm"
      >
        Add Alert +
      </Button>
    </div>
  )
}

const IndicatorType = {
  dec: <TablerIcons.IconTrendingDown className="h-8 w-8" />,
  inc: <TablerIcons.IconTrendingUp className="h-8 w-8" />,
  active: (
    <TablerIcons.IconCircleDashedCheck className="h-8 w-8 text-yellow-500" />
  ),
}

const StatsCard = ({
  stat,
  label,
  metadata,
  containerClass,
  indicator,
  iconColor,
}: {
  stat: string
  label: string
  metadata?: any
  containerClass?: string
  indicator?: string
  iconColor?: string
}) => {
  return (
    <div
      className={twMerge(
        "flex w-full flex-col gap-2 rounded-md border border-black/5 bg-white p-2 shadow-sm",
        containerClass
      )}
    >
      <p className="text-sm font-medium">{label}</p>
      <div className="flex flex-row items-center justify-between">
        <p className="text-2xl font-medium">{stat}</p>
        {metadata && (
          <div className="flex flex-row items-center gap-1">
            {Object.entries(metadata).map(([key, value]) => (
              <Indicator
                key={key}
                isProcessed={key === "Resolved"}
                type="status"
                label={key}
                text={value as string}
              />
            ))}
          </div>
        )}
        {indicator && (
          <div className="flex flex-row items-center gap-1 text-green-700">
            {indicator === "inc" ? (
              <p className="text-xs font-medium">{"12%"}</p>
            ) : indicator === "dec" ? (
              <p className="text-xs font-medium">{"8%"}</p>
            ) : (
              <p className="text-xs font-medium text-yellow-500">{"6%"}</p>
            )}
            {IndicatorType[indicator]}
          </div>
        )}
      </div>
    </div>
  )
}

function Alerts() {
  const [showAlerts, setShowAlerts] = React.useState<boolean>(true)
  const {
    isOpen: isSetAlertsDrawerOpen,
    onOpen: onSetAlertsDrawerOpen,
    onOpenChange: onSetAlertsDrawerOpenChange,
  } = useDisclosure()
  return (
    <div className="animate-fade animate-once animate-ease-linear mr-4 flex w-full grid-cols-7 flex-col justify-between gap-4 overflow-scroll rounded-md border-black/5 bg-onyx-white  p-4 transition-all duration-150">
      <div
        className={twMerge(
          "flex max-h-fit w-full flex-col  transition-all duration-150 scrollbar-hide",
          showAlerts ? "h-[100%] overflow-visible" : "h-9 overflow-clip"
        )}
      >
        <div className="flex flex-row justify-end">
          <Button
            variant="flat"
            color="secondary"
            className="m-2 max-h-fit w-fit max-w-fit p-0"
            onClick={() => {
              // setShowAlerts(!showAlerts) // old implmentation of dropdown menu
              onSetAlertsDrawerOpen()
            }}
          >
            <Indicator
              isProcessed={true}
              type="status"
              label="Alerts Control"
            />
          </Button>
        </div>
        {/* <SetAlertsMenu /> */}
      </div>
      <div className="relative col-span-3 flex h-full w-full flex-col gap-2">
        <Indicator
          isProcessed={false}
          type="status"
          label="4 New Alerts"
          containerClass="mt-4 mb-2"
        ></Indicator>
        <div className="grid grid-cols-6 justify-between gap-2">
          <StatsCard
            stat="101"
            label="Total Alerts / Flagged Instances"
            metadata={{Resolved: "98", Active: "3"}}
            containerClass="col-span-3"
          />

          <StatsCard
            stat="200"
            label="Users Monitored"
            containerClass="col-span-1"
            indicator="inc"
          />

          <StatsCard
            stat="6 hours"
            label="Avg Time to Resolution"
            containerClass="col-span-1"
            indicator="dec"
          />
          <StatsCard
            stat="10"
            label="Number of Cases"
            containerClass="col-span-1"
            indicator="active"
          />
        </div>

        <Accordion
          selectionMode="multiple"
          defaultSelectedKeys={["unresolved"]}
        >
          <AccordionItem
            value="unresolved"
            key="unresolved"
            title={
              <div>
                <p className="text-xs font-medium">Unresolved</p>
              </div>
            }
          >
            <div className="flex w-full flex-col justify-between gap-2">
              <AlertTableItemHeader
                alert={{
                  type: "clientAndInvestorProtectionAlerts",
                  subtitle: "highRiskTransactions",
                  message: "Ben & Diego mentioned the phrase “delete this”",
                  number_of_conversations: 2,
                }}
                priority={0}
              />
              <AlertTableItemHeader
                alert={{
                  type: "advertisingAndMarketingViolations",
                  subtitle: "incompleteOrMissingDisclosures",
                  message:
                    "Ben may not have provided the necessary disclosures",
                  number_of_conversations: 1,
                }}
                priority={3}
              />

              <AlertTableItemHeader
                alert={{
                  type: "nonCompliantCommunications",
                  subtitle: "platformSubversion",
                  message:
                    "Shantanu may not have responded to clients question or circumvented approved communications channel",
                }}
                priority={2}
              />
            </div>
          </AccordionItem>
          <AccordionItem
            value="resolved"
            key="resolved"
            title={<p className="text-xs font-medium">Resolved</p>}
          >
            <div className="flex w-full flex-col justify-between gap-2">
              {alertsStub.map((alert) => (
                <AlertTableItemHeader
                  key={alert.id}
                  alert={{
                    type: alert.group,
                    subtitle: alert.title,
                    message: alert.description,
                    number_of_conversations: alert.conversations,
                  }}
                  priority={[Math.floor(Math.random() * 5)]}
                />
              ))}
            </div>
          </AccordionItem>
        </Accordion>
      </div>
      <SetAlertsDrawerMenu
        isOpen={isSetAlertsDrawerOpen}
        onOpenChange={onSetAlertsDrawerOpenChange}
        // submitFilters={submitFilters}
      />
    </div>
  )
}

export default Alerts
