// src/lib/utils.ts

import {type ClassValue, clsx} from "clsx"
import {twMerge} from "tailwind-merge"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function validateEmail(value: string): boolean {
  return value.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i) !== null
}

export function parseMapString(mapString: string): {
  [key: string]: string | boolean
} {
  const result: {[key: string]: string | boolean} = {}

  // Check that the string has the expected format
  if (mapString.startsWith("map[") && mapString.endsWith("]")) {
    // Remove the prefix and suffix
    const content = mapString.substring(4, mapString.length - 1)

    // Split by space to get key:value pairs
    const pairs = content.split(" ")
    for (const pair of pairs) {
      const [key, value] = pair.split(":")
      // Convert "true"/"false" strings to booleans, otherwise keep as string
      if (value === "true") {
        result[key] = true
      } else if (value === "false") {
        result[key] = false
      } else {
        result[key] = value
      }
    }
  }

  return result
}
