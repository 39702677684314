import React from "react"
import Logo from "./Logo"
import {twMerge} from "tailwind-merge"
import {cn} from "@/lib/utils"
interface LogoHeaderProps {
  size?: number
  classNames?: {
    containerClass?: string
    logoClass?: string
    logoContainerClass?: string
    textClass?: string
  }
}

const LogoHeader: React.FC<LogoHeaderProps> = ({size = 24, classNames}) => {
  return (
    <div
      className={twMerge(
        "flex flex-row items-center gap-1 text-2xl font-medium tracking-tight text-onyx-black",
        classNames?.containerClass
      )}
    >
      <Logo
        type="svg"
        size={size}
        classNames={{
          logoClass: classNames?.logoClass,
          logoContainerClass: classNames?.logoContainerClass,
        }}
      />
      <div className={classNames?.textClass}>Quartz Intelligence</div>
    </div>
  )
}

export default LogoHeader
