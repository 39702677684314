// src/components/archive/EmailView/utils.tsx

import DOMPurify from "dompurify"

/**
 * Sanitizes the raw email HTML content by extracting the <body> content
 * and cleaning it using DOMPurify to prevent XSS attacks.
 */
export function sanatizeEmailHTML(html: string): {
  sanitizedContent: string | null
  prunedContent: string | null
} {
  if (!html) return {sanitizedContent: null, prunedContent: null}
  const parser = new DOMParser()
  const doc = parser.parseFromString(html, "text/html")
  const bodyContent = doc.body ? doc.body.innerHTML : ""

  const sanitizedContent = DOMPurify.sanitize(bodyContent, {
    ALLOWED_TAGS: [
      "html",
      "body",
      "div",
      "span",
      "p",
      "h1",
      "h2",
      "h3",
      "h4",
      "h5",
      "h6",
      "font",
      "hr",
    ],
    ALLOWED_ATTR: [
      "class",
      "style",
      "id",
      "dir",
      "tabindex",
      "color",
      "face",
      "size",
    ],
  })

  const prunedContent = DOMPurify.sanitize(bodyContent, {
    ALLOWED_TAGS: ["div"],
    ALLOWED_ATTR: [],
  })

  return {sanitizedContent, prunedContent}
}

export enum AlertTypes {}

// Define an enum for all alert subfields with titles including spaces
export enum ComplianceAlert {
  // Alerts for SEC and FINRA Compliance Risks – Non-Compliant Communications
  UnapprovedCommunicationChannels = "Unapproved Communication Channels",
  UnapprovedSocialMediaUsage = "Unapproved Social Media Usage",
  MisleadingOrPromissoryLanguage = "Misleading Or Promissory Language",

  // Alerts for SEC and FINRA Compliance Risks – Failure to Archive
  FailureToArchive = "Failure To Archive",

  // Alerts for SEC and FINRA Compliance Risks – Advertising and Marketing Violations
  MisleadingAdvertising = "Misleading Advertising",
  UseOfTestimonials = "Use Of Testimonials",

  // Alerts for SEC and FINRA Compliance Risks – Incomplete or Missing Disclosures
  IncompleteOrMissingDisclosures = "Incomplete Or Missing Disclosures",

  // Trade and Transaction Monitoring Alerts
  UnauthorizedTrading = "Unauthorized Trading",
  InsiderTrading = "Insider Trading",
  CrossTrading = "Cross Trading",
  TradeAllocations = "Trade Allocations",

  // Personal Trading and Employee Conduct
  PersonalAccountTrading = "Personal Account Trading",
  GiftsAndEntertainment = "Gifts And Entertainment",
  OutsideBusinessActivities = "Outside Business Activities",
  PoliticalContributions = "Political Contributions",

  // Client and Investor Protection Alerts
  ComplaintHandling = "Complaint Handling",
  HighRiskTransactions = "High Risk Transactions",
  FeeDisclosures = "Fee Disclosures",
  ElderFinancialAbuse = "Elder Financial Abuse",

  // Policy and Regulatory Breaches
  ConflictOfInterest = "Conflict Of Interest",
  ConfidentialityViolations = "Confidentiality Violations",
  MarketManipulation = "Market Manipulation",
  LateOrMissingFilings = "Late Or Missing Filings",

  // Escalation and Audit Alerts
  EscalatedIssues = "Escalated Issues",
  DataAccessBreaches = "Data Access Breaches",
  AuditPreparation = "Audit Preparation",

  // Behavioral and Sentiment Analysis Alerts
  AnomalousBehavior = "Anomalous Behavior",
  HighRiskKeywords = "High Risk Keywords",
  ClientDistressSignals = "Client Distress Signals",
}

// A mapping from each alert type to its description
export const ComplianceAlertDescriptions: Record<ComplianceAlert, string> = {
  // Alerts for SEC and FINRA Compliance Risks – Non-Compliant Communications
  [ComplianceAlert.UnapprovedCommunicationChannels]:
    "Alert for business-related discussions conducted on platforms like WhatsApp, Signal, or personal email, which are not monitored or archived.",
  [ComplianceAlert.UnapprovedSocialMediaUsage]:
    "Detect mentions of business activities on unmonitored or unauthorized social media accounts.",
  [ComplianceAlert.MisleadingOrPromissoryLanguage]:
    "Flag phrases like 'guaranteed returns,' 'risk-free,' or any unrealistic claims.",

  // Alerts for SEC and FINRA Compliance Risks – Failure to Archive
  [ComplianceAlert.FailureToArchive]:
    "Alert when communication logs (e.g., emails, chats, texts) are not successfully captured, archived, or are deleted.",

  // Alerts for SEC and FINRA Compliance Risks – Advertising and Marketing Violations
  [ComplianceAlert.MisleadingAdvertising]:
    "Monitor for claims violating SEC Rule 206(4)-1 (Advisers Act Marketing Rule), such as unsubstantiated performance data.",
  [ComplianceAlert.UseOfTestimonials]:
    "Alert for the use of client testimonials without proper disclosures, as required by the Marketing Rule.",

  // Alerts for SEC and FINRA Compliance Risks – Incomplete or Missing Disclosures
  [ComplianceAlert.IncompleteOrMissingDisclosures]:
    "Alert when communications with clients omit required disclosures, such as risks associated with specific investments or conflicts of interest.",

  // Trade and Transaction Monitoring Alerts
  [ComplianceAlert.UnauthorizedTrading]:
    "Alerts for trading activity not authorized by the client, such as excessive trading or unsuitable transactions.",
  [ComplianceAlert.InsiderTrading]:
    "Monitor and flag communications involving non-public, material information that could suggest insider trading.",
  [ComplianceAlert.CrossTrading]:
    "Alert for discussions or activities related to trades between client accounts without proper documentation and compliance with regulations.",
  [ComplianceAlert.TradeAllocations]:
    "Flag potential violations in allocation practices, such as favoritism toward specific accounts.",

  // Personal Trading and Employee Conduct
  [ComplianceAlert.PersonalAccountTrading]:
    "Alert for employees discussing or executing trades in their personal accounts that might conflict with client interests or violate the firm’s Code of Ethics.",
  [ComplianceAlert.GiftsAndEntertainment]:
    "Monitor communications involving gifts or entertainment exceeding firm or regulatory limits.",
  [ComplianceAlert.OutsideBusinessActivities]:
    "Alert for discussions of outside employment, directorships, or affiliations that have not been disclosed or approved.",
  [ComplianceAlert.PoliticalContributions]:
    "Monitor for potential violations of SEC Rule 206(4)-5 (Pay-to-Play Rule), such as contributions to political campaigns that could lead to conflicts of interest.",

  // Client and Investor Protection Alerts
  [ComplianceAlert.ComplaintHandling]:
    "Alert for any communication indicating dissatisfaction, client complaints, or potential disputes, ensuring timely escalation and resolution.",
  [ComplianceAlert.HighRiskTransactions]:
    "Flag discussions about risky or speculative investments that may not align with the client’s objectives or risk tolerance.",
  [ComplianceAlert.FeeDisclosures]:
    "Alert for communications where fees or charges are misrepresented, not disclosed, or disputed.",
  [ComplianceAlert.ElderFinancialAbuse]:
    "Monitor for signs of exploitation, such as requests for unusual withdrawals, pressure to liquidate accounts, or third-party interference.",

  // Policy and Regulatory Breaches
  [ComplianceAlert.ConflictOfInterest]:
    "Alert for discussions that suggest undisclosed conflicts, such as steering clients toward investments tied to the adviser’s financial gain.",
  [ComplianceAlert.ConfidentialityViolations]:
    "Monitor for potential breaches of Regulation S-P or client confidentiality, such as sharing sensitive client information over insecure channels.",
  [ComplianceAlert.MarketManipulation]:
    "Flag communications related to coordinated trading activities or attempts to influence market prices.",
  [ComplianceAlert.LateOrMissingFilings]:
    "Alert for communications or discussions that suggest missed regulatory deadlines, such as Form ADV amendments or other required disclosures.",

  // Escalation and Audit Alerts
  [ComplianceAlert.EscalatedIssues]:
    "Automatically alert when a compliance issue has not been addressed or resolved within a specified timeframe.",
  [ComplianceAlert.DataAccessBreaches]:
    "Alert for unusual access patterns or attempts to view archived communications outside of normal protocols.",
  [ComplianceAlert.AuditPreparation]:
    "Monitor for gaps or inconsistencies in communication records that may arise during routine or surprise audits.",

  // Behavioral and Sentiment Analysis Alerts
  [ComplianceAlert.AnomalousBehavior]:
    "Use AI to detect unusual language, sentiment shifts (e.g., stress, urgency), or deviations from typical communication patterns.",
  [ComplianceAlert.HighRiskKeywords]:
    "Create alerts for keywords or phrases indicating fraud, misconduct, or violations (e.g., 'cash only,' 'delete this,' 'off the books').",
  [ComplianceAlert.ClientDistressSignals]:
    "Alert for language from clients that indicates confusion, concern, or dissatisfaction with investment strategies.",
}
