import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import {HeroUIProvider} from "@heroui/react"

import reportWebVitals from "./reportWebVitals"
import {createBrowserRouter, RouterProvider, Navigate} from "react-router-dom"

import {helix} from "ldrs"
import Archive from "./routes/archive.tsx"

import Landing from "./routes/landing.tsx"
import Root from "./routes/root.tsx"

import Login from "./routes/login.tsx"
import ErrorPage from "./error-page"

// Confirm the link is a sign-in with email link.
// const auth = getAuth()

import {loadErrorMessages, loadDevMessages} from "@apollo/client/dev"

import {QueryClientProvider} from "@tanstack/react-query"

import Alerts from "./routes/alerts.tsx"
import {ConfigureIntegrations} from "./routes/auth/onboarding/configureIntegrations.tsx"
import {User} from "./routes/user.tsx"
import {queryClient} from "./queryConfig.ts"
import UserContextProvider from "./contexts/UserContextProvider.tsx"

import MagicLinkCallback from "./routes/auth/callback/magicLink.tsx"
import OTPCallback from "./routes/auth/callback/otp.tsx"
import ConfirmUserCallback from "./routes/auth/callback/confirmUser.tsx"
import AuthenticatedRouteProvider from "./utils/providers/AuthenticatedRouteProvider.tsx"
import BIAlerts from "./routes/bi_alerts.tsx"

if (true) {
  // Adds messages only in a dev environment
  loadDevMessages()
  loadErrorMessages()
}

const RootProvider = () => {
  return (
    <AuthenticatedRouteProvider>
      <Root />
    </AuthenticatedRouteProvider>
  )
}

export const Loading = () => {
  helix.register()
  return (
    <div className="flex h-full w-full animate-appearance-out flex-col items-center justify-center gap-4">
      <l-helix size="130" speed="4.5" color="black"></l-helix>
      <p className="font-monaspaceNeon text-sm">LOADING CORTEX</p>
      <p className="text-xs">
        If loading persists, please navigate to{" "}
        <a href="/home" className="self-end text-xs underline">
          www.onyxindustrials.com/home
        </a>
      </p>
    </div>
  )
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <Landing />,
    errorElement: <ErrorPage />,
  },
  {
    element: <RootProvider />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/archive",
        element: (
          // <AuthenticatedRoute>
          <Archive />
          // </AuthenticatedRoute>
        ),
        errorElement: <ErrorPage />,
      },
      {
        path: "/alerts",
        element: <Alerts />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/business-intelligence",
        element: <BIAlerts />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/user",
        element: (
          // <AuthenticatedRoute>
          <QueryClientProvider client={queryClient}>
            <User />
          </QueryClientProvider>
          // </AuthenticatedRoute>
        ),
        errorElement: <ErrorPage />,
      },
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/auth/callback/magic_link",
    element: <MagicLinkCallback />,
  },
  {
    path: "/auth/callback/otp",
    element: <OTPCallback />,
  },
  // { same as /auth/callback/magic_link, but this is old
  //   path: "/auth/confirm",
  //   element: <Confirm />,
  // },
  {
    path: "/auth/onboarding/configure_integrations",
    element: <ConfigureIntegrations />,
  },

  {
    path: "/auth/callback/confirm_user",
    element: <ConfirmUserCallback />,
  },
  {
    path: "/auth/callback",
    element: <ConfigureIntegrations />,
  },
])

ReactDOM.createRoot(document.getElementById("root")).render(
  <HeroUIProvider>
    <React.StrictMode>
      {/* <SupabaseStateWrapper> */}
      <UserContextProvider>
        <RouterProvider router={router} />
      </UserContextProvider>
      {/* </SupabaseStateWrapper> */}
    </React.StrictMode>
  </HeroUIProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
