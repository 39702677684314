import {AlertGroupEnum, AlertTypeEnum} from "./shared"

// Define an interface for each alert (subcategory)
export interface AlertType {
  name: string
  note: string
}

// Define an interface for a major alert category that contains multiple alert types
export interface AlertCategory {
  name: string
  subtypes: AlertType[]
}

// Create an array of alert categories with their corresponding alert types and notes
// Create an array of alert groups with their corresponding alert subtypes and notes
export const alertGroups: AlertCategory[] = [
  {
    name: AlertGroupEnum.nonCompliantCommunications,
    subtypes: [
      {
        name: AlertTypeEnum.unapprovedCommunicationChannels,
        note: "Alert for business-related discussions conducted on platforms like WhatsApp, Signal, or personal email, which are not monitored or archived.",
      },
      {
        name: AlertTypeEnum.unapprovedSocialMediaUsage,
        note: "Detect mentions of business activities on unmonitored or unauthorized social media accounts.",
      },
      {
        name: AlertTypeEnum.misleadingOrPromissoryLanguage,
        note: "Flag phrases like “guaranteed returns,” “risk-free,” or any unrealistic claims.",
      },
      {
        name: AlertTypeEnum.platformSubversion,
        note: "Detect attempts to use non-approved platforms for business communications, such as personal email accounts or unmonitored social media. Specifcally try to attempt to determine if there is missing context and therefor there is reason to believe that the communication is happening off the platform",
      },
    ],
  },
  {
    name: AlertGroupEnum.advertisingAndMarketingViolations,
    subtypes: [
      {
        name: AlertTypeEnum.misleadingAdvertising,
        note: "Monitor for claims violating SEC Rule 206(4)-1 (Advisers Act Marketing Rule), such as unsubstantiated performance data.",
      },
      {
        name: AlertTypeEnum.useOfTestimonials,
        note: "Alert for the use of client testimonials without proper disclosures, as required by the Marketing Rule.",
      },
      {
        name: AlertTypeEnum.incompleteOrMissingDisclosures,
        note: "Alert when communications with clients omit required disclosures, such as risks associated with specific investments or conflicts of interest.",
      },
    ],
  },
  {
    name: AlertGroupEnum.tradeAndTransactionMonitoringAlerts,
    subtypes: [
      {
        name: AlertTypeEnum.unauthorizedTrading,
        note: "Alerts for trading activity not authorized by the client, such as excessive trading or unsuitable transactions.",
      },
      {
        name: AlertTypeEnum.insiderTrading,
        note: "Monitor and flag communications involving non-public, material information that could suggest insider trading.",
      },
      {
        name: AlertTypeEnum.crossTrading,
        note: "Alert for discussions or activities related to trades between client accounts without proper documentation and compliance with regulations.",
      },
      {
        name: AlertTypeEnum.tradeAllocations,
        note: "Flag potential violations in allocation practices, such as favoritism toward specific accounts.",
      },
    ],
  },
  {
    name: AlertGroupEnum.personalTradingAndEmployeeConduct,
    subtypes: [
      {
        name: AlertTypeEnum.personalAccountTrading,
        note: "Alert for employees discussing or executing trades in their personal accounts that might conflict with client interests or violate the firm’s Code of Ethics.",
      },
      {
        name: AlertTypeEnum.giftsAndEntertainment,
        note: "Monitor communications involving gifts or entertainment exceeding firm or regulatory limits.",
      },
      {
        name: AlertTypeEnum.outsideBusinessActivities,
        note: "Alert for discussions of outside employment, directorships, or affiliations that have not been disclosed or approved.",
      },
      {
        name: AlertTypeEnum.politicalContributions,
        note: "Monitor for potential violations of SEC Rule 206(4)-5 (Pay-to-Play Rule), such as contributions to political campaigns that could lead to conflicts of interest.",
      },
    ],
  },
  {
    name: AlertGroupEnum.clientAndInvestorProtectionAlerts,
    subtypes: [
      {
        name: AlertTypeEnum.complaintHandling,
        note: "Alert for any communication indicating dissatisfaction, client complaints, or potential disputes, ensuring timely escalation and resolution.",
      },
      {
        name: AlertTypeEnum.highRiskTransactions,
        note: "Flag discussions about risky or speculative investments that may not align with the client’s objectives or risk tolerance.",
      },
      {
        name: AlertTypeEnum.feeDisclosures,
        note: "Alert for communications where fees or charges are misrepresented, not disclosed, or disputed.",
      },
      {
        name: AlertTypeEnum.elderFinancialAbuse,
        note: "Monitor for signs of exploitation, such as requests for unusual withdrawals, pressure to liquidate accounts, or third-party interference.",
      },
    ],
  },
  {
    name: AlertGroupEnum.policyAndRegulatoryBreaches,
    subtypes: [
      {
        name: AlertTypeEnum.conflictOfInterest,
        note: "Alert for discussions that suggest undisclosed conflicts, such as steering clients toward investments tied to the adviser’s financial gain.",
      },
      {
        name: AlertTypeEnum.confidentialityViolations,
        note: "Monitor for potential breaches of Regulation S-P or client confidentiality, such as sharing sensitive client information over insecure channels.",
      },
      {
        name: AlertTypeEnum.marketManipulation,
        note: "Flag communications related to coordinated trading activities or attempts to influence market prices.",
      },
      {
        name: AlertTypeEnum.lateOrMissingFilings,
        note: "Alert for communications or discussions that suggest missed regulatory deadlines, such as Form ADV amendments or other required disclosures.",
      },
    ],
  },
  {
    name: AlertGroupEnum.escalationAndAuditAlerts,
    subtypes: [
      {
        name: AlertTypeEnum.escalatedIssues,
        note: "Automatically alert when a compliance issue has not been addressed or resolved within a specified timeframe.",
      },
      {
        name: AlertTypeEnum.dataAccessBreaches,
        note: "Alert for unusual access patterns or attempts to view archived communications outside of normal protocols.",
      },
      {
        name: AlertTypeEnum.auditPreparation,
        note: "Monitor for gaps or inconsistencies in communication records that may arise during routine or surprise audits.",
      },
    ],
  },
  {
    name: AlertGroupEnum.behavioralAndSentimentAnalysisAlerts,
    subtypes: [
      {
        name: AlertTypeEnum.anomalousBehavior,
        note: "Use AI to detect unusual language, sentiment shifts (e.g., stress, urgency), or deviations from typical communication patterns.",
      },
      {
        name: AlertTypeEnum.highRiskKeywords,
        note: "Create alerts for keywords or phrases indicating fraud, misconduct, or violations (e.g., “cash only,” “delete this,” “off the books”).",
      },
      {
        name: AlertTypeEnum.clientDistressSignals,
        note: "Alert for language from clients that indicates confusion, concern, or dissatisfaction with investment strategies.",
      },
    ],
  },
]
