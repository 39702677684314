import {Button, Chip, Divider} from "@heroui/react"
import {twMerge} from "tailwind-merge"
import {IconAlertSquareRoundedFilled} from "@tabler/icons-react"
import * as TablerIcons from "@tabler/icons-react"
import {
  AlertTypeMessageIcon,
  IconComponent,
} from "@/components/archive/AlertsView/shared"
import AlertsTableItemView from "@/components/archive/AlertsView/AlertTableItemView"
import {useState} from "react"

const raw_html = `<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <title>Email to Mike</title>
  <style>
    body {
      background-color: #f4f4f4;
      font-family: Arial, sans-serif;
      margin: 0;
      padding: 20px;
    }
    .email-container {
      background-color: #ffffff;
      border: 1px solid #ddd;
      max-width: 600px;
      margin: auto;
      padding: 20px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    }
    .email-header {
      border-bottom: 1px solid #ddd;
      margin-bottom: 20px;
      padding-bottom: 10px;
    }
    .email-footer {
      border-top: 1px solid #ddd;
      margin-top: 20px;
      padding-top: 10px;
      font-size: 0.9em;
      color: #555;
    }
  </style>
</head>
<body>
  <div class="email-container">
    <div class="email-header">
    </div>
    <div>
    <p>Hey Mike,</p>
    </div>
    <div class="email-body">
      <p>I have some inside info on XYZ Corp. It’s about to make a big move. Let’s talk before the news drops.</p>
    </div>
    <div class="email-footer">
      <p>Best regards,<br>Your Name</p>
    </div>
  </div>
</body>
</html>`

const raw_html_bi = `<!DOCTYPE html>
<html>
<body>
    <div class="container">
    <div>
        <p>URGENT: Immediate Transfer Required</p>
        </div>
        <div>
        <p>Hey,</p>
        </div>
        <div>
        <p>What the hell is going on? I need $50,000 transferred <strong>right now</strong>. No delays. Don’t give me any BS about policies—I don’t care. Just get it done, or I swear I’m taking this up the chain, and someone’s gonna have to answer for it.</p>
        </div>
        <div>
        <p>If this isn’t resolved immediately, I’m closing all my accounts. I’m serious.</p>
        </div>
        <div>
        <p>Ben</p>
        </div>
    </div>
</body>
</html>`

const AlertDrop = ({flag}: {flag?: "compliance" | "bi"}) => {
  return (
    <div className="flex flex-col gap-2 rounded-lg border border-black/5 p-2">
      {/* <IconAlertCircle size={20} color="#ef4444" /> */}
      <div className="flex h-fit flex-col gap-2 rounded-md border border-black/5 bg-onyx-slate/15 p-2 py-1 bg-grid-small-onyx-black/15">
        <div className="flex flex-row items-center justify-between gap-2">
          {/* <div className="flex flex-col gap-0 px-2 py-1">
            <p className="text-xs font-medium">Timestamp</p>
            <p className="text-sm">
              Alert Description about what happened or what the AI thinks is
              happening
            </p>
          </div> */}
          <div className="flex flex-col gap-0 px-2 py-1">
            <div className="flex flex-col gap-0 py-1">
              <p className="text-xs font-medium">Reason for Alert:</p>
              <p className="text-sm">
                {flag === "bi"
                  ? "Sudden change in Ben's communication style - using aggressive language and making unrealistic promises to clients. Ben’s communication style suddenly changed, showing aggressive language and unrealistic demands, which could indicate financial distress, fraud, or unauthorized account access. This message contains: Aggressive language (e.g., “absolutely ridiculous,” “someone is going to regret it”), Unrealistic requests (e.g., demanding a large transfer “immediately—no questions asked”) Threatening escalation (e.g., “I will escalate this to the highest authority”)"
                  : "Potential insider trading / MNPI (Material Non-Public Information) disclosure."}
              </p>
            </div>
            {flag !== "bi" && (
              <div className="flex flex-col gap-0 py-1">
                <p className="text-xs font-medium">Regulatory Concern:</p>
                <p className="text-sm">
                  Violation of SEC Rule 10b-5 (prohibiting fraudulent statements
                  and insider trading).
                </p>
              </div>
            )}
            <p className="text-xs font-medium">Priority: HIGH</p>
          </div>
          <div className="flex flex-col items-end gap-2">
            {/* <IconAlertCircle size={20} color="#ef4444" /> */}
            <Button
              title="Mark as Resolved"
              variant="bordered"
              color="success"
              className="max-h-fit w-fit max-w-fit "
              //   endContent={<IconChevronRight size={14} color="dodgerblue" />}
            >
              <p className="text-xs font-medium">Mark as Resolved</p>
            </Button>
            <Button
              title="Generate Report"
              variant="bordered"
              color="primary"
              className="max-h-fit w-fit max-w-fit "

              //   endContent={<IconChevronRight size={14} color="dodgerblue" />}
            >
              <p className="text-xs font-medium">Generate Report</p>
            </Button>
            <Button
              title="Escalate Case"
              variant="bordered"
              color="danger"
              className="max-h-fit w-fit max-w-fit "
              //   endContent={<IconChevronRight size={14} color="dodgerblue" />}
            >
              <p className="text-xs font-medium">Escalate Case</p>
            </Button>
          </div>
        </div>
        <div className="flex flex-col gap-2 border-b border-black/5 pb-2 ">
          <Divider className="border-black/5" />
          <p className="px-2 text-xs font-medium">Flagged Messages:</p>
          <AlertsTableItemView
            sender={["John Doe"]}
            receiver={["Jane Doe"]}
            message={""}
            subject={
              flag === "bi"
                ? "Exclusive Stock Tip - Confidential"
                : "Exclusive Stock Tip - Confidential"
            }
            raw_content={flag === "bi" ? raw_html_bi : raw_html}
            createdAt={"2024-01-01"}
            cc={[]}
            bcc={[]}
            idx={0}
          />
        </div>
      </div>
    </div>
  )
}

const AlertPriorityIcon = [
  <TablerIcons.IconAlertCircle size={16} color="#ef44449F" />,
  <TablerIcons.IconAlertCircle size={16} color="#f59e0b9F" />,
  <TablerIcons.IconAlertCircle size={16} color="#10b9819F" />,
  <TablerIcons.IconAlertCircle size={16} color="#10b9819F" />,
  <TablerIcons.IconAlertCircle size={16} color="#10b9819F" />,
]

const AlertTableItemHeader = ({
  alert,
  priority = 0,
  flag,
}: {
  alert: {
    subtitle: string
    description?: string
    type: string
    message: string
    number_of_conversations?: number
  }
  priority?: number
  flag?: "compliance" | "bi"
}) => {
  const [open, setOpen] = useState(false)
  return (
    <div className="group cursor-pointer">
      <div
        id="alert-item"
        className="z-10 flex w-full flex-row items-center gap-2" // Added 'group' here
        onClick={() => setOpen(!open)}
      >
        <div className="flex flex-row gap-2 rounded-lg border border-red-500/5 p-1">
          {AlertPriorityIcon[priority]}
        </div>
        <div className="flex w-full flex-row items-center gap-2 ">
          <AlertTypeMessageIcon
            className=""
            message={alert.message}
            type={alert.type}
            subtitle={alert.subtitle}
          />
          <p className="text-sm">{alert.message}</p>
        </div>

        <p className="flex w-fit whitespace-nowrap text-xs">
          {new Date().toLocaleString()}
        </p>
      </div>
      <div
        id="alert-drop"
        className={twMerge(
          "h-fit max-h-0 overflow-clip transition-all duration-300",
          open ? "max-h-screen" : "max-h-0"
        )} // Modified className
      >
        <AlertDrop flag={flag} />
      </div>
    </div>
  )
}

export default AlertTableItemHeader
