import {useEffect, useState} from "react"
import {useNavigate, useSearchParams} from "react-router-dom"
import supabase from "../../../supabaseConfig"
import {AuthApiError} from "@supabase/supabase-js"
import AuthError from "@/components/auth/AuthError"
import {Input} from "@heroui/react"
import OTPContainer from "@/components/auth/OTPContainer"
import {Button} from "@heroui/react"
import {parseMapString} from "@/lib/utils"

type encodedParams = {
  encodedParams: string
}

type decodedParams = {
  token_hash: string
  type: string
  meta: string
  redirect_route: string
}

export default function OTPCallback() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [error, setError] = useState(false)

  const meta = searchParams.get("meta")
  const meta_json = parseMapString(meta || "")
  const session_email = meta_json.email

  if (supabase.auth.getSession()) {
    navigate(
      "/auth/callback/confirm_user" +
        "?token_hash=" +
        searchParams.get("token_hash") +
        "&type=" +
        searchParams.get("type") +
        "&meta=" +
        searchParams.get("meta")
    )
  }
  useEffect(() => {
    // DEPRECATED - was used for automatically signing user in

    const fetchSession = async () => {
      const token_hash = searchParams.get("token_hash")

      if (!token_hash) {
        console.error("Missing token_hash or type in URL parameters")
        navigate("/auth/login")
        return
      }

      try {
        const {data, error} = await supabase.auth.verifyOtp({
          token_hash,
          type: "magiclink",
        })

        if (data.session) {
          navigate("/archive")
        } else if (error) {
          console.error("Error verifying OTP:", error.message)
          // Check if the error message indicates an expired or invalid link
          if (error instanceof AuthApiError) {
            setError(true)
            // navigate("/auth/login")
          } else {
            // navigate("/auth/login") // Default error navigation
            navigate("/login")
          }
        }
      } catch (error) {
        console.error("Exception when verifying OTP:", error)
        navigate("/auth/login")
      }
    }

    // fetchSession()
  }, [navigate, searchParams])

  return (
    <div className="flex h-screen flex-col items-center justify-center bg-onyx-slate/20 bg-grid-small-onyx-black/25">
      {error ? (
        <AuthError type="login_timeout" />
      ) : (
        <div className="">
          <OTPContainer
            emailProp={session_email as string}
            routeProps={{
              token_hash: searchParams.get("token_hash"),
              type: searchParams.get("type"),
              meta: searchParams.get("meta"),
              redirect_route: searchParams.get("redirect_route"),
            }}
          />
        </div>
      )}
    </div>
  )
}
