import {sanatizeEmailHTML} from "./utils"
import {EmailMessage} from "@/utils/graphQL/queries/threads/shared"

import {Checkbox, Tooltip} from "@heroui/react"
import {useEffect, useState} from "react"
import {twMerge} from "tailwind-merge"
import ChannelIndicator from "@/components/archive/ChannelIndicator"
interface AlertsTableItemViewProps {
  sender: string[]
  receiver: string[]
  message: string
  subject: string
  raw_content: string
  createdAt: string
  cc: string[]
  bcc: string[]
  idx: number
}

const AlertsTableItemView = ({
  sender,
  receiver,
  message,
  subject,
  raw_content,
  createdAt,
  cc,
  bcc,
  idx,
}: AlertsTableItemViewProps) => {
  const [contentView, setContentView] = useState<"cleaned" | "raw">("cleaned")
  const [sanitizedContent, setSanitizedContent] = useState<string>("")
  const [messageContent, setMessageContent] = useState<string>(message)
  const sameSender = false

  useEffect(() => {
    const {sanitizedContent, prunedContent} = sanatizeEmailHTML(raw_content)
    if (sanitizedContent) {
      setSanitizedContent(sanitizedContent)
      setMessageContent(prunedContent)
    } else {
      setSanitizedContent(message)
      setMessageContent(message)
    }
    console.log("sanitizedContent:", sanitizedContent)
  }, [message, raw_content])
  return (
    <div
      className={twMerge(
        "flex flex-col gap-1",
        sameSender ? "px-2" : "px-2 pt-2"
      )}
      key={idx}
    >
      {!sameSender && (
        <div className="flex flex-row items-center gap-2">
          <div className="flex w-full flex-row  justify-between gap-1 rounded-md border border-black/5 bg-onyx-white px-2 py-1 text-xs">
            <div className="flex flex-col gap-1 text-xs">
              <div className="flex flex-row items-center gap-1 pt-1">
                <ChannelIndicator
                  channel_type="email"
                  size="sm"
                  containerClass="px-[2px] py-[2px]"
                />
                <p className="text-xs font-medium ">Subject: {subject}</p>
              </div>
              <p className="font-regular text-xs text-neutral-400">
                {idx + 1} |{" "}
                {new Date(createdAt).toLocaleDateString("en-US", {
                  month: "short",
                  day: "numeric",
                  year: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                  second: "2-digit",
                  hour12: true,
                })}
              </p>
              <div className="flex flex-row gap-2">
                <p>
                  From: <span className="font-medium">{sender.join(", ")}</span>
                </p>
                <div className="h-1 w-1 self-center rounded-full bg-onyx-black/50" />
                <p>
                  To: <span className="font-medium">{receiver.join(", ")}</span>
                </p>
                {cc.length > 0 && (
                  <p>
                    Cc: <span className="font-medium">{cc.join(", ")}</span>
                  </p>
                )}
                {bcc.length > 0 && (
                  <p>
                    Bcc: <span className="font-medium">{bcc.join(", ")}</span>
                  </p>
                )}
              </div>
            </div>
            <div className="flex flex-col gap-1 self-end">
              <Tooltip
                content={
                  <div className="">
                    <div className="text-tiny font-medium">
                      Emails are sanitized by default.
                    </div>
                    <div className="text-tiny">
                      Click to view raw email content. content.
                    </div>
                  </div>
                }
                placement="bottom-start"
              >
                <Checkbox
                  size="sm"
                  classNames={{
                    wrapper: "me-1",
                    label: "text-[10px]",
                  }}
                  isSelected={contentView === "raw"}
                  onValueChange={() => {
                    setContentView(contentView === "raw" ? "cleaned" : "raw")
                  }}
                >
                  Raw
                </Checkbox>
              </Tooltip>
            </div>
          </div>
        </div>
      )}
      {contentView === "cleaned" ? (
        <div className="ml-1 flex flex-row items-center gap-2 rounded-sm bg-onyx-slate p-2 px-2">
          {message ? (
            <p className="text-xs font-[350]">
              {messageContent || "Loading..."}
            </p>
          ) : (
            <div
              className="raw-content-container flex flex-col gap-1 text-sm"
              dangerouslySetInnerHTML={{
                __html: messageContent,
              }}
            />
          )}
        </div>
      ) : (
        <div className="ml-1 rounded-sm bg-onyx-slate p-1 px-2">
          <p className="mb-2 text-xs font-medium">Raw Email Content:</p>
          <div
            className="raw-content-container text-sm"
            dangerouslySetInnerHTML={{
              __html: sanitizedContent,
            }}
          />
        </div>
      )}
    </div>
  )
}

export default AlertsTableItemView
