import {Input, Switch} from "@heroui/react"
import {
  AlertSubTypeMessageIcon,
  IconComponent,
} from "@/components/archive/AlertsView/shared"
import {IconEdit, IconTrash} from "@tabler/icons-react"
import {twMerge} from "tailwind-merge"

const AlertConfigurationItem = ({
  note,
  type,
  subtype,
  idx,
  priority,
  ifCondition,
}: {
  note: string
  type: string
  subtype: string
  idx: number
  priority?: string
  ifCondition?: string
}) => {
  return (
    <div className="group flex w-full flex-col justify-between border border-onyx-black/5 bg-onyx-slate">
      <div className="grid grid-cols-12 py-1 ">
        <AlertSubTypeMessageIcon
          className="col-span-4 mx-1"
          message=""
          type={type}
          subtitle={subtype}
        />
        <div className="col-span-7 flex flex-col gap-2 self-center">
          <p className="flex-shrink text-xs">{note}</p>
        </div>
        <div className="col-span-1 flex w-fit flex-row items-center gap-2">
          {/* <Switch className="" size="sm" /> */}
          <IconEdit
            size={16}
            className=" text-onyx-black/35 opacity-0 transition-opacity group-hover:opacity-100"
          />
          <IconTrash
            size={16}
            className=" text-danger-500/35 opacity-0 transition-opacity group-hover:opacity-100"
          />
        </div>
      </div>
      {/* {ifCondition && (
          <p className="text-xs font-medium ">
            ADDITIONAL ACTION:{" "}
            <span className="font-normal text-onyx-black">{ifCondition}</span>
          </p>
        )}
        {priority && (
          <p className="text-xs font-medium ">
            PRIORITY:{" "}
            <span
              className={twMerge(
                "rounded-md px-1 py-0.5 font-normal text-onyx-white",
                priority.length >= 3 && "bg-red-500",
                priority.length === 2 && "bg-yellow-500",
                priority.length === 1 && "bg-green-500"
              )}
            >
              {priority}
            </span>
          </p>
        )} */}
    </div>
  )
}

export default AlertConfigurationItem
